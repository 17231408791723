import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../components/it/layout"
import SEO from "../../components/seo"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Img from "gatsby-image"
import Container from "react-bootstrap/Container"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
      query {
          policies: directusCompanyPolicy(language: {eq: "it"}) {
              cookie_policy
          }
          image: file(relativePath: { eq: "policies.png" }) {
              childImageSharp {
                  fluid {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
      }
  `)

  const meta = [
    {
      name: 'robots',
      content: 'noindex',
    }
  ]

  return (
    <Layout>
      <SEO title={"Cookie Policy"} description={null} lang={"it"} meta={meta} />
      <Container fluid>
        <Row className={"site-content"}>
          <Col md={5} className={"offset-md-1"}>
            <h1 className={"mb-3"}>Cookie Policy</h1>
            <div dangerouslySetInnerHTML={{ __html:  data.policies.cookie_policy }}/>
          </Col>
          <Col xs={12} sm={12} md={6} className={"p-0"}>
            <Img fluid={data.image.childImageSharp.fluid} style={{ width: `100%` }} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default IndexPage
